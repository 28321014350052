import * as d3 from 'd3';
import * as crossfilter from 'crossfilter';
import * as dc from 'dc';

$(() => {
  if ($('#d3-data-source').length === 0) {
    return;
  }

  d3.json($('#d3-data-source').val()).then((data) => {
    $('.dc-loading-message').hide();

    if (data.time_logs.length > 0) {
      $('.dc-error-message ').hide();
    } else {
      $('#analytics-results').hide();
      $('.dc-error-message ').show();
      return;
    }

    for (let i = 0; i < data.time_logs.length; i++) {
      var dateParts = data.time_logs[i].date.split(/\D+/);

      data.time_logs[i].date =
        new Date(
          parseInt(dateParts[0]),
          parseInt(dateParts[1]) - 1, // JavaScript months start at 0
          parseInt(dateParts[2])
        );
    }

    var dateValues = data.time_logs.map(timeLog => timeLog.month_name);
    var uniqueDateValues = dateValues.filter(onlyUnique);

    const ndx = crossfilter(data.time_logs);

    // Total Count display
    var totalCountDisplay = new dc.numberDisplay('#time-logs-count');
    totalCountDisplay
      .group(ndx.groupAll().reduceCount())
      .valueAccessor(function (c) { return c; })
      .formatNumber(function (num) { return Math.round(num); });
    totalCountDisplay.render();

    // Display total time of time logs
    var totalTime = ndx.dimension(function (t) {
      return t.time;
    }).groupAll().reduce(
      function add(p, c) {
        p.push(c);

        return p;
      },
      function remove(p, c) {
        p.splice(p.indexOf(c), 1);

        return p;
      },
      function initial() {
        return [];
      }
    );

    var totalTimeDisplay = new dc.numberDisplay('#total-time-display')
    totalTimeDisplay
      .group(totalTime)
      .valueAccessor(function (timeLogs) {
        if (timeLogs.length === 0) {
          return 0;
        }

        return timeLogs.reduce((sum,timeLog) => sum + timeLog.time, 0);
      })
      .formatNumber(function (minutes) { return formatMinutesAsHoursMinutes(minutes) });
    totalTimeDisplay.render();

    // Display total billable time
    var totalBillableTime = ndx.dimension(function (t) {
      if (t.billable === 'Billable') {
        return t.time;
      } else {
        return 0;
      }
    }).groupAll().reduce(
      function add(p, c) {
        p.push(c);

        return p;
      },
      function remove(p, c) {
        p.splice(p.indexOf(c), 1);

        return p;
      },
      function initial() {
        return [];
      }
    );

    var totalBillableTimeDisplay = new dc.numberDisplay('#total-billable-time')
    totalBillableTimeDisplay
      .group(totalBillableTime)
      .valueAccessor(function (timeLogs) {
        if (timeLogs.length === 0) {
          return 0;
        }

        return timeLogs.reduce(
          (sum,timeLog) => sum + (timeLog.billable === 'Billable' ? timeLog.time : 0), 0
        );
      })
      .formatNumber(function (minutes) { return formatMinutesAsHoursMinutes(minutes) });
    totalBillableTimeDisplay.render();

    // Display total non billable time
    var totalNonBillableTime = ndx.dimension(function (t) {
      if (t.billable === 'Billable') {
        return 0;
      } else {
        return t.time;
      }
    }).groupAll().reduce(
      function add(p, c) {
        p.push(c);

        return p;
      },
      function remove(p, c) {
        p.splice(p.indexOf(c), 1);

        return p;
      },
      function initial() {
        return [];
      }
    );

    var totalNonBillableTimeDisplay = new dc.numberDisplay('#total-non-billable-time')
    totalNonBillableTimeDisplay
      .group(totalNonBillableTime)
      .valueAccessor(function (timeLogs) {
        if (timeLogs.length === 0) {
          return 0;
        }

        return timeLogs.reduce(
          (sum,timeLog) => sum + (timeLog.billable === 'Billable' ? 0 : timeLog.time), 0
        );
      })
      .formatNumber(function (minutes) { return formatMinutesAsHoursMinutes(minutes) });

    totalNonBillableTimeDisplay.render();

    // By Loggable Type
    var byTypeChart = new dc.pieChart('#by-type');
    var byTypeDimension = ndx.dimension(function(d) {
      return d.type;
    });
    var byTypeGroup = byTypeDimension.group().reduceSum(d => d.time);
    byTypeChart
      .width(300)
      .height(300)
      .dimension(byTypeDimension)
      .group(byTypeGroup)
      .title(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`)
      .label(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`);
    byTypeChart.render();

    // Billables vs. Non-billables
    var billableChart = new dc.pieChart('#billables');
    var billableDimension = ndx.dimension(function(d) {
      return d.billable;
    });
    var billableGroup = billableDimension.group().reduceSum(d => d.time);
    billableChart
      .width(300)
      .height(300)
      .dimension(billableDimension)
      .group(billableGroup)
      .title(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`)
      .label(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`);
    billableChart.render();

    // Group by Month tracked
    var monthTracked = ndx.dimension(function (d) {
      return d.month_name;
    });
    var monthTrackedGroup = monthTracked.group().reduceSum(d => d.time);
    var timeByMonthChart = new dc.barChart('#time-by-month');

    timeByMonthChart
      .width(820)
      .height(480)
      .dimension(monthTracked)
      .group(monthTrackedGroup)
      .x(d3.scaleBand().domain(uniqueDateValues).range([0, 768]))
      .xUnits(dc.units.ordinal)
      .brushOn(false)
      .title(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`)
      .label(d => `${d.data.key}: ${formatMinutesAsHoursMinutes(d.data.value)}`);
    timeByMonthChart.yAxis().tickFormat(formatMinutesAsHoursMinutes);
    timeByMonthChart.render();

    // Render Customer Data
    var byCustomerDimension = ndx.dimension(function (d) {
      return d.customer;
    });
    var customerGroup = byCustomerDimension.group().reduceSum(d => d.time);
    var byCustomerChart = new dc.rowChart('#by-customer')

    byCustomerChart
      .width(768)
      .height(480)
      .dimension(byCustomerDimension)
      .group(customerGroup)
      .cap(20)
      .title(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`)
      .label(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`);
    byCustomerChart.xAxis().tickFormat(formatMinutesAsHoursMinutes);
    byCustomerChart.render();

    // Render Project Data
    var byProjectDimension = ndx.dimension(function (d) {
      if (d.project_name === null) {
        return 'No Project'
      } else {
        return d.project_name
      }
    });
    var projectGroup = byProjectDimension.group().reduceSum(d => d.time);
    var byProjectChart = new dc.rowChart('#by-project')

    byProjectChart
      .width(768)
      .height(480)
      .dimension(byProjectDimension)
      .group(projectGroup)
      .cap(20)
      .title(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`)
      .label(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`);
    byProjectChart.xAxis().tickFormat(formatMinutesAsHoursMinutes);
    byProjectChart.render();

    var byProvidersDim = ndx.dimension(function (d) {
      return d.providers;
    });

    // Inspired by Providers Chart in ITP
    // Have to hack this because these libraries are not designed
    // for multi-dimensional arrays!
    var byProvidersGroup = byProvidersDim.groupAll().reduce(
      function add(p, d) {
        if (d.providers[0] === '') { return p; } // Skip empty values
        d.providers.forEach(function (value, index) {
          p[value] = (p[value] || 0) + d.time; // Increment counts
        });
        return p;
      },
      function remove(p, d) {
        if (d.providers[0] === '') { return p; } // Skip empty values
        d.providers.forEach(function (value, index) {
          p[value] = (p[value] || 0) - d.time; // Decrement counts
        });
        return p;
      },
      function initial() {
        return {};
      }
    ).value();

    // Hack. See http://jsfiddle.net/jeffsteinmetz/cwShL
    byProvidersGroup.all = function () {
      var newObject = [];
      for (var key in this) {
        if (this.hasOwnProperty(key) && key !== 'all') {
          newObject.push({
            key: key,
            value: this[key]
          });
        }
      }

      // Top 20
      newObject.sort(function (a, b) {
        if (a.value > b.value) { return -1 }
        if (a.value < b.value) { return 1 }
        return 0;
      });
      return newObject.slice(0, 20);
    };

    var byProvidersChart = new dc.rowChart('#by-provider');

    byProvidersChart
      .width(768)
      .height(480)
      .dimension(byProvidersDim)
      .group(byProvidersGroup)
      .title(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`)
      .label(d => `${d.key}: ${formatMinutesAsHoursMinutes(d.value)}`);
    byProvidersChart.xAxis().tickFormat(formatMinutesAsHoursMinutes);
    byProvidersChart.render();

    return { ndx: ndx, data: data };
  });
});

// Inspired by 'formatted_hours' in time_log_decorator.rb
function formatMinutesAsHoursMinutes(timeTotal) {
  var hours = parseInt(timeTotal / 60);
  var minutes = timeTotal % 60;
  var time = '';

  if (hours != 0) {
    if (hours < 10) {
      time += '0' + hours + ':';
    } else {
      time += hours + ':'
    }
  } else {
    time += '00:'
  }

  if (minutes < 10) {
    time += '0' + minutes;
  } else {
    time += minutes;
  }

  return time;
}

// https://stackoverflow.com/a/14438954/16337316
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
