const oldChosen = $.fn.chosen;

$(() => {
  // Something causes jQuery to unset the `chosen` function; this restores it
  $.fn.extend({ chosen: oldChosen });

  var chosenOptions;
  chosenOptions = {
    allow_single_deselect: true,
    no_results_text: 'No results matched'
  };
  $('.chzn-select').chosen(chosenOptions);
  $(document).on('CA:modal_loaded', function(event, modal) {
    $(modal).find('.chzn-select').chosen(chosenOptions);
  });

  $('.chzn-select').on('invalid', function () {
    var $select = $(this);
    $select.next("div.chosen-container").append('<div class="select-error">This field is required.</div>');
  });
});
